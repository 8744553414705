
html {
  background-color: mintcream;
  background: url('../img/wood-background.png') no-repeat 0 0 fixed;
  background-size: 100% 100%;
}

/* Limit the display width on large screens */
@media screen and (min-width: 1440px) {
  body { 
    max-width: 1440px; 
    margin: 0 auto !important; 
    float: none !important; 
  }
}

/* Margin is 100% the initial margin */
@media screen and (min-width: 961px) {
  .margin-container {
    margin-left: 10em;
    margin-right: 10em;
    margin-Top: 1em;
  }
}

/* Margin is about 50% the initial margin when the screen is 1/2 the max screen size */
@media screen and (max-width: 960px) {
  .margin-container {
    margin-left: 5em;
    margin-right: 5em;
    margin-Top: 0.5em;
  }
}

/* Hide the link that should open and close the topnav on small screens */
@media screen and (min-width: 601px) {
  .topnav .icon {
    display: none;
  }
}

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
/* Margin is about 10% the initial margin when the screen size is small */
@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {display: none;}
    .topnav a.icon {
      float: right;
      display: block;
    }

    .topnav.responsive {position: relative;}
    .topnav.responsive a.icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .topnav.responsive a {
      float: none;
      display: block;
      text-align: left;
    }

    .margin-container {
      margin-left: 1em;
      margin-right: 1em;
      margin-Top: 0.5em;
    }
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: #333;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 20px 20px;
  min-width: 125px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add an active class to highlight the current page */
.topnav a.active {
  background-color: teal;
  color: white;
}

.wood-background {
  background-color: mintcream;
  background: url('../img/wood-background.png') no-repeat 0 0 scroll;
  background-size: 100% 100%;
  height:100%;
  width:100%;
}

.paper-background {
  border-radius: 25px;
  background: url('../img/paper-background.gif');
  background-position: left top;
  background-repeat: repeat;
}

.paper-background-box {
  border: 2px solid teal;
  border-radius: 25px;
  background: url('../img/paper-background.gif');
  background-position: left top;
  background-repeat: repeat;
  padding: 20px;
}

/* Container holding the image and the text */
.container {
  position: relative;
  text-align: center;
  color: white;
}

.container-text {
  text-align: center;
  color: black;
  font-size: 18px;
}

/* */
.footer {
  margin-top: 2em;
  padding-top: 2em;
  background-color: #333;
}

.footer-title {
  font-weight: bolder;
}

.footer li {
  display: block;
}

/* */
.contact {
  margin-top: 1em;
}

.contact-form {
  margin-top: 2em;
}

.contact-form select {
  min-height: 33px;
  border-radius: 0.5em;
}

.content {
  color: black;
  margin-top: 3em;
}

.contact-wrapper {
  margin-top: 1em;
}

#comments {
  min-height: 10em;
}

/* faq */
.faq-container p {
  font-size: 1.2em;
}